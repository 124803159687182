export type PanelProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>

export const Panel = ({ children, ...props }: PanelProps) => {
  return (
    <div
      {...props}
      className={`font-sans text-mydra-black leading-6 p-6 border border-lines rounded-2xl ${props.className ?? ''}`}
    >
      {children}
    </div>
  )
}
