import SkillsService, { ISkillGetFilters } from '@/app/services/skills'
import {
  ErrorResponse,
  IPaginationParams,
  PaginatedQueryResponse,
} from '@/types/api'
import { ISkill } from '@/types/skill'
import { useQuery, UseQueryOptions } from 'react-query'

export function useSkills(
  filters: ISkillGetFilters = {},
  pagination: IPaginationParams = {},
  options?: Omit<
    UseQueryOptions<PaginatedQueryResponse<ISkill>, ErrorResponse>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery<PaginatedQueryResponse<ISkill>, ErrorResponse>(
    [SkillsService.GET_PATH, filters, pagination],
    () => SkillsService.getSkills(filters, pagination),
    options
  )
}
