import { useAuth } from '@/app/providers/Auth/Auth'
import Image from 'next/image'
import { Button } from '@/app/modules/shared/components'
import { useTranslations } from 'next-intl'
import { EXTERNAL_LINKS } from '@/app/lib/constants'

type CoursesHeaderProps = {
  openModal: () => void
  wrapperProps?: React.HTMLProps<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

export const CoursesHeader = ({
  openModal,
  wrapperProps,
  ...props
}: CoursesHeaderProps) => {
  const { firstName } = useAuth()
  const t = useTranslations('Courses')

  const redirectToAICopilot = () => {
    window.open(EXTERNAL_LINKS.careerCopilot, '_blank', 'noopener')
  }

  return (
    <div
      {...wrapperProps}
      className={`bg-gradient-to-b from-white to-[#FFF9F1] ${wrapperProps?.className ?? ''}`}
    >
      <div
        {...props}
        className={`py-12 sm:py-20 flex flex-col gap-10 ${props.className ?? ''}`}
      >
        <div className="text-3xl md:text-4xl font-title">
          {t('welcome', { name: firstName })} 👋🏼
        </div>
        <div className="text-base md:text-lg">{t('intro_message')}</div>
        <div className="grid grid-rows-1 sm:grid-cols-2 gap-7">
          <div className="flex flex-row items-center gap-5 p-5 bg-mydra-light-orange sm:p-8 rounded-xl">
            <div className="flex flex-col justify-between h-full gap-6 flex-[5]">
              <div className="text-2xl md:text-3xl font-title">
                {t('tailor_search_title')}
              </div>
              <div className="text-base md:text-lg">
                {t('tailor_search_description')}
              </div>
              <Button color="black" onClick={openModal}>
                {t('search_now')}
              </Button>
            </div>
            <div className="hidden md:flex items-end flex-[4] w-full relative self-end">
              <Image
                src={'/images/homepage-banner-top-1.png'}
                alt={t('filter_home_banner')}
                layout="responsive"
                width={782}
                height={625}
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-5 p-5 bg-mydra-violet sm:p-8 rounded-xl">
            <div className="flex flex-col justify-between h-full gap-6 flex-[5]">
              <div className="text-2xl md:text-3xl font-title">
                {t('generate_ai_path_title')}
              </div>
              <div className="text-base md:text-lg">
                {t('generate_ai_path_description')}
              </div>
              <Button color="black" onClick={redirectToAICopilot}>
                {t('access_now')}
              </Button>
            </div>
            <div className="hidden md:flex items-end flex-[4] w-full relative">
              <Image
                src={'/images/homepage-banner-top-2.png'}
                alt={t('career_path_home_banner')}
                layout="responsive"
                width={782}
                height={625}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
