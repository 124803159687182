import { ISkill } from '@/types/skill'
import { ApiService } from './ApiService'
import { IPaginationParams, PaginatedQueryResponse } from '@/types/api'
import qs from 'qs'

export interface ISkillGetFilters {
  slug?: string
}

export type ISkillFilters = Partial<
  Record<keyof ISkillGetFilters, string | string[] | boolean | number>
>

export type ISkillGetParams = {
  filters?: ISkillFilters
  pagination?: IPaginationParams
}

class SkillsService extends ApiService {
  static GET_PATH = `/skills`

  static async getSkills(
    filters: ISkillGetFilters = {},
    pagination: IPaginationParams = {}
  ): Promise<PaginatedQueryResponse<ISkill>> {
    const queryString = qs.stringify({ ...filters, ...pagination })

    return ApiService.get(`${SkillsService.GET_PATH}?${queryString}`)
  }
}

export default SkillsService
