import React from 'react'

export type CloseFilledProps = React.HtmlHTMLAttributes<SVGElement> & {
  color?: string
}

export const CloseFilled = ({ color, ...props }: CloseFilledProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      fill="none"
      {...props}
    >
      <path
        d="M20.5837 6.944L19.0562 5.4165L13.0003 11.4723L6.94449 5.4165L5.41699 6.944L11.4728 12.9998L5.41699 19.0557L6.94449 20.5832L13.0003 14.5273L19.0562 20.5832L20.5837 19.0557L14.5278 12.9998L20.5837 6.944Z"
        fill={color ?? 'currentColor'}
      />
    </svg>
  )
}
