import { useTranslations } from 'next-intl'

export function NoCoursesMessage({ onClearFiltersClick, ...props }) {
  const t = useTranslations('Courses')
  return (
    <div
      {...props}
      className={`flex flex-col items-center justify-center rounded-3xl text-center bg-white px-4 py-9 gap-4 ${props.className}`}
    >
      <h2 className="text-lg font-medium text-black leading-[normal]">
        {t('no_results')}
      </h2>
      <p className="text-text max-w-[185px]">{t('clear_filters_message')}</p>
      <button
        onClick={() => onClearFiltersClick()}
        className="p-4 py-1 mt-6 text-black bg-[--lines] rounded-md hover:bg-black hover:text-white"
      >
        {t('clear_filters_button')}
      </button>
    </div>
  )
}
