import { v4 } from 'uuid'

export function uuidToStableIntegerId(uuid = v4(), maxInteger = 4) {
  uuid = uuid.replace(/-/g, '').toLowerCase()

  const highBits = parseInt(uuid.substr(0, 8), 16)
  const lowBits = parseInt(uuid.substr(24, 8), 16)

  const stableId = ((highBits + lowBits) % maxInteger) + 1

  return stableId
}

export function shuffleArray<T>(array: T[]): T[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
