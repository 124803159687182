import { IEducationProvider } from './education-provider'

export enum AgreementStatus {
  Draft = 'draft',
  Active = 'active',
  Inactive = 'inactive',
}

export enum FeeType {
  Flat = 'flat',
  Escalation = 'escalation',
}

export enum Origin {
  Lead = 'lead',
  Financing = 'financing',
}

export enum ContractType {
  EpFinancing = 'ep-financing',
  EpContract = 'ep-contract',
  AffiliateProgramme = 'affiliate-programme',
}

export interface IFee {
  origin: string
  type: FeeType
  discount: number
  maxStudents?: number
  maxRevenue?: number
}

export interface IEducationProviderAgreement {
  _id: string
  educationProvider: IEducationProvider | string
  contractType: ContractType
  fees: IFee[]
  startAt: string
  endAt?: string
  status: AgreementStatus
  contractUrl?: string
  dropoutPolicy?: string
  emailForNotifications: string // TODO: migrate this to education provider's contacts
  studentsInAgreement: number
  revenue: number
  createdAt?: string
  updatedAt?: string
}
