import { ICourse } from '@/types/course'
import { Headline2 } from '@/app/components/Typography'
import { Panel } from '@/app/components/Panel/Panel'
import { useTranslations } from 'next-intl'
import { ImageWithFallback } from '../../shared/components/image-with-fallback'

type CourseTestimonialsProps = {
  courseInfo: Required<Pick<ICourse, 'testimonials'>>
} & React.HTMLAttributes<HTMLDivElement>

export const CourseTestimonials = ({
  courseInfo,
  ...props
}: CourseTestimonialsProps) => {
  const t = useTranslations('CoursePage')
  const { testimonials = [] } = courseInfo

  if (!Array.isArray(testimonials) || !testimonials.length) {
    return null
  }

  return (
    <div
      className={`flex flex-col gap-6 pb-8 md:gap-12 md:pb-20 ${props.className ?? ''}`}
    >
      <Headline2 className="text-center">
        {t('what_students_say_about_this_course')}
      </Headline2>
      <ul className="flex flex-col gap-4 overflow-x-auto md:gap-8 md:grid md:grid-cols-3 md:justify-center no-scrollbar">
        {testimonials
          .slice(0, 3)
          .map(({ comment, author, authorRole, avatarUrl }, index) => (
            <li key={index} className="flex">
              <Panel key={index} className="flex flex-col w-full gap-6">
                <blockquote>“{comment}”</blockquote>
                <div className="flex items-center gap-3">
                  {avatarUrl && (
                    <ImageWithFallback
                      src={avatarUrl}
                      alt={`${author}`}
                      width={40}
                      height={40}
                      className={`rounded-full w-10 h-10 overflow-hidden`}
                    />
                  )}
                  <div>
                    <p className="text-sm font-semibold">{author}</p>
                    <p className="text-sm">{authorRole}</p>
                  </div>
                </div>
              </Panel>
            </li>
          ))}
      </ul>
    </div>
  )
}
