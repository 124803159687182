import { useTranslations } from 'next-intl'
import { PATH_WITH_SUPPORTED_LOCALES_REGEX } from './constants'

type TranslatableConstant = {
  name: string
  id: string
  translationKey?: string
  [index: string]: unknown
}

export function translateConstant(
  constant: TranslatableConstant,
  t: ReturnType<typeof useTranslations<string>>,
  prefix: string
): TranslatableConstant {
  return {
    ...constant,
    name: constant.translationKey
      ? t(constant.translationKey)
      : t(`${prefix}_${constant.id ?? constant.slug ?? constant.name}`),
  }
}
// TODO: extract these locales to a constant
export function isPathWithLocale(path?: string): boolean {
  return PATH_WITH_SUPPORTED_LOCALES_REGEX.test(path ?? '')
}
