import { ICourse } from '@/types/course'
import {
  COURSE_IMAGE_VARIANTS,
  DEFAULT_CARD_COVERS,
  IMAGES_FOR_CATEGORIES,
} from '../lib/constants'
import { uuidToStableIntegerId } from '../lib/helpers'
import { ICategory } from '@/types/category'

export const useCourseCategoryImage = (
  course: Pick<ICourse, '_id' | 'categories' | 'educationProvider'>
) => {
  if (!course) {
    return {
      category: null,
      categoryImage: null,
    }
  }

  const { _id: courseId, categories: [courseCategory] = [] } = course

  const categoryImages =
    IMAGES_FOR_CATEGORIES[(courseCategory as ICategory)?.slug]

  if (!categoryImages?.length) {
    const defaultImages = DEFAULT_CARD_COVERS.filter(
      (image) => image?.variant === COURSE_IMAGE_VARIANTS.THUMBNAIL
    )

    const defaultImageId =
      uuidToStableIntegerId(courseId, defaultImages.length) || 1
    const defaultImage = defaultImages[defaultImageId - 1]

    return {
      category: null,
      categoryImage: defaultImage,
    }
  }

  const categoryImageId =
    uuidToStableIntegerId(courseId, categoryImages.length) || 1
  const defaultImage = categoryImages[categoryImageId - 1]

  return {
    category: courseCategory,
    categoryImage: defaultImage,
  }
}
