import { useCohortHelpers } from '@/app/hooks/useCohortHelpers'
import { useFormatters } from '@/app/hooks/useFormatters'
import { ICourse } from '@/types/course'

type CoursePriceProps = {
  course: Pick<ICourse, 'educationProvider' | 'cohorts'>
  selectedCohortId?: string
  amountProps?: React.HTMLAttributes<HTMLParagraphElement>
  noAmountProps?: React.HTMLAttributes<HTMLParagraphElement>
} & React.HTMLAttributes<HTMLParagraphElement>

export function CoursePrice({
  course,
  selectedCohortId,
  amountProps,
  noAmountProps,
  ...props
}: CoursePriceProps) {
  const { formatCoursePrice } = useFormatters()
  const { isCohortActive } = useCohortHelpers()
  const { cohorts } = course || {}

  const cost = selectedCohortId
    ? cohorts?.find(({ _id }) => _id === selectedCohortId)?.cost
    : cohorts?.filter(isCohortActive)?.[0]?.cost ?? cohorts?.[0]?.cost

  const { amount } = cost || {}

  const formattedCoursePrice = formatCoursePrice({ ...course, cost })

  return Number.isInteger(amount) ? (
    <p
      {...props}
      {...amountProps}
      className={`text-lg font-sans text-gray-900 font-semibold leading-6 ${
        props.className ?? ''
      } ${amountProps?.className ?? ''}`}
    >
      {formattedCoursePrice}
    </p>
  ) : (
    <p
      {...props}
      {...noAmountProps}
      className={`text-sm text-[--text] font-medium ${props.className ?? ''} ${noAmountProps?.className ?? ''}`}
    >
      {formattedCoursePrice}
    </p>
  )
}
