import qs from 'query-string'

import { ICategory } from '@/types/category'
import { ApiService } from './ApiService'
import {
  IApiIndividualResponse,
  IPaginationParams,
  PaginatedQueryResponse,
} from '@/types/api'

export interface ICategoryGetFilters {
  skills?: string[]
  minPrice?: number
  maxPrice?: number
  maxDuration?: number
  categories?: string[]
  subCategories?: string[]
  type?: string
}

export type ICategoryFilters = Partial<
  Record<keyof ICategoryGetFilters, string | string[] | boolean | number>
>

export type ICategoryGetParams = {
  filters?: ICategoryFilters
  pagination?: IPaginationParams
}

export class CategoryService extends ApiService {
  static GET_PATH = `/categories`

  static async getCategories(
    filters: ICategoryGetFilters = {},
    pagination: IPaginationParams = {}
  ): Promise<PaginatedQueryResponse<ICategory>> {
    const queryString = qs.stringify({ ...filters, ...pagination })

    return ApiService.get(`${CategoryService.GET_PATH}?${queryString}`)
  }

  static async getCategory(
    id: string
  ): Promise<IApiIndividualResponse<ICategory>> {
    return ApiService.get(`${CategoryService.GET_PATH}/${id}`)
  }
}
