import React, { useCallback, useEffect, useRef, useState } from 'react'
import './style.css'

export const MultiRangeSlider = ({
  min,
  max,
  onChange,
  value,
  valFormatter = (val) => val,
  big = false,
  ...props
}) => {
  const [minVal, setMinVal] = useState(value?.min ?? min)
  const [maxVal, setMaxVal] = useState(value?.max ?? max)
  const minValRef = useRef(value?.min ?? min)
  const maxValRef = useRef(value?.max ?? max)
  const range = useRef(null)

  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  return (
    <div
      className={`range-slider-container ${!!big ? 'big' : ''} ${props.className ?? ''}`}
    >
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1)
          setMinVal(value)
          minValRef.current = value
          onChange({ min: value, max: maxVal })
        }}
        className="thumb thumb--left"
        style={{ zIndex: minVal > max - 100 && '5' }}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1)
          setMaxVal(value)
          maxValRef.current = value
          onChange({ min: minVal, max: value })
        }}
        className="thumb thumb--right"
      />

      <div className="slider">
        <div className="slider__track" />
        <div ref={range} className="slider__range" />
        <div className="slider__left-value !text-black">
          {valFormatter(minVal)}
        </div>
        <div className="slider__right-value !text-black">
          {valFormatter(maxVal)}
        </div>
      </div>
    </div>
  )
}
