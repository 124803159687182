import React, { useCallback, useEffect } from 'react'
import { COURSE_DURATIONS, COURSE_TYPES } from '../constants'
import { AutoComplete, Button } from '@/app/modules/shared/components'
import { MultiAutoComplete } from '../../shared/components/multi-auto-complete/MultiAutoComplete'
import { useCategories } from '@/app/hooks/data-hooks/useCategories'
import { useSkills } from '@/app/hooks/data-hooks/useSkills'
import { useEducationProviders } from '@/app/hooks/data-hooks/useEducationProviders'
import { useTranslations } from 'next-intl'
import { translateConstant } from '@/app/lib/i18n'
import { Controller, useForm } from 'react-hook-form'
import Modal from '@/app/components/Modal/Modal'
import { Headline5 } from '@/app/components/Typography'
import { MultiRangeSlider } from '@/app/components/MultiRangeSlider'
import { MAX_COURSE_FILTER_PRICE, ROUTES } from '@/app/lib/constants'
import { Switch } from '@headlessui/react'
import { ContractType } from '@/types/agreement'
import { InfoTooltip } from '../../fix-pay-simulator/components/FixPaySimulatorForm/InfoTooltip'
import { useFormatters } from '@/app/hooks/useFormatters'

interface CourseFiltersModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (data: any) => Promise<void>
  onClear?: () => void
  hideOnClear?: boolean
  defaultValues?: any
}

export const CourseFiltersModal = ({
  open,
  onSubmit,
  onClear,
  onClose,
  hideOnClear,
  defaultValues,
}: CourseFiltersModalProps) => {
  const t = useTranslations('Courses')
  const tFilter = useTranslations('FilterModal')
  const { formatCurrencyValue } = useFormatters()

  const {
    control,
    watch,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      categories: [],
      subCategories: [],
      skills: [],
      educationProvider: [],
      type: [],
      maxDuration: null,
      agreementType: defaultValues?.agreementType ?? '',
      budget: {
        min: defaultValues?.budget?.min ?? 0,
        max: defaultValues?.budget?.max ?? MAX_COURSE_FILTER_PRICE,
      },
      ...defaultValues,
    },
  })

  const numberOfSelectedFilters = Object.entries(watch()).filter(
    ([key, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0
      }
      if (key === 'budget') {
        return value.min !== 0 || value.max !== MAX_COURSE_FILTER_PRICE
      }
      return !!value
    }
  ).length

  const {
    data: { data: categories = [] } = {},
    isLoading: isLoadingCategories,
  } = useCategories({}, { limit: 500 }, { retry: false })

  const selectedCategoriesValues = watch('categories')
  const selectedCategories = (categories ?? []).filter(({ _id }) => {
    return Array.isArray(selectedCategoriesValues)
      ? selectedCategoriesValues?.find(({ id }) => id === _id)
      : [selectedCategoriesValues]?.filter(Boolean)
  })

  const subCategories = categories
    .filter((cat) =>
      (selectedCategories.map(({ _id }) => _id) ?? []).includes(cat._id)
    )
    .map((cat) => cat.subCategories)
    .flat()

  const { data: { data: skills = [] } = {}, isLoading: isLoadingSkills } =
    useSkills({}, { limit: 500 }, { retry: false })
  const {
    data: { data: educationProviders = [] } = {},
    isLoading: isLoadingEducationProvider,
  } = useEducationProviders({}, { limit: 500 }, { retry: false })

  const isLoading =
    isLoadingSkills || isLoadingCategories || isLoadingEducationProvider

  const getDefaultValues = useCallback(
    () =>
      Object.entries(defaultValues ?? {}).reduce((acc, [key, value]) => {
        if (
          [
            'categories',
            'subCategories',
            'skills',
            'type',
            'educationProvider',
          ].includes(key)
        ) {
          return {
            ...acc,
            [key]: (Array.isArray(value)
              ? value.map((categoryId) => ({ id: categoryId }))
              : value
                ? [{ id: value }]
                : []
            ).filter(Boolean),
          }
        }

        if (['maxDuration'].includes(key)) {
          return {
            ...acc,
            [key]: value ? { id: value } : null,
          }
        }

        return {
          ...acc,
          [key]: value ?? null,
        }
      }, {}),
    [defaultValues]
  )

  useEffect(() => {
    if (isLoading) {
      return
    }

    reset()
    const newValues = getDefaultValues()
    for (const key in newValues) {
      setValue(key, newValues[key])
    }
  }, [defaultValues, setValue, getDefaultValues, reset, isLoading])

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={t('all_filters', { numberOfSelectedFilters })}
      className="md:min-w-[780px]"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-5 w-full"
      >
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="categories"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('categories')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...categories.map(({ _id: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />

          <Controller
            name="subCategories"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('subcategories')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...subCategories.map(({ _id: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="skills"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('skills')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...skills.map(({ slug: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />

          <Controller
            name="educationProvider"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('education_providers')}
                subTitle={t('add_one_or_more')}
                options={[
                  ...educationProviders.map(({ _id: id, name }) => ({
                    id,
                    name,
                  })),
                ].sort((a, b) => a.name.localeCompare(b.name))}
                {...field}
              />
            )}
          />
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>
        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <Controller
            name="type"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <MultiAutoComplete
                title={t('course_type')}
                subTitle={t('add_one_or_more')}
                options={COURSE_TYPES.map((courseType) =>
                  translateConstant(courseType, tFilter, 'type')
                )}
                {...field}
              />
            )}
          />

          <Controller
            name="maxDuration"
            control={control}
            disabled={isLoading}
            render={({ field }) => (
              <AutoComplete
                title={t('course_duration')}
                subTitle={t('add_one')}
                options={COURSE_DURATIONS.map((duration) =>
                  translateConstant(duration, tFilter, 'duration')
                )}
                {...field}
              />
            )}
          />
        </div>

        <div className="mx-8 h-[1] border-b border-lines"></div>

        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <div className="col-span-2">
            <Controller
              control={control}
              name="agreementType"
              render={({ field: { value, onChange, ...field } }) => (
                <>
                  <div className="flex gap-5 items-center">
                    <Switch
                      {...field}
                      id="agreementType"
                      checked={value === ContractType.EpFinancing}
                      onChange={(checked) => {
                        if (checked) {
                          onChange(ContractType.EpFinancing)
                        } else {
                          onChange('')
                        }
                      }}
                      className="group inline-flex overflow-visible h-4 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-[#b098d6]"
                    >
                      <span className="size-6 border shadow-sm rounded-full drop-shadow-toggle1 drop-shadow-toggle2 drop-shadow-toggle3 bg-white transition group-data-[checked]:translate-x-6 group-data-[checked]:bg-mydra-purple group-data-[checked]:border-transparent" />
                    </Switch>
                    <label
                      htmlFor="agreementType"
                      className="flex flex-1 justify-between"
                    >
                      <div className="flex flex-col">
                        <span>{tFilter('payment-option')}</span>
                        <div className="flex gap-2">
                          <span className="text-sm text-[--text]">
                            {tFilter('pay-in-instalments')}
                          </span>
                          <span className="px-2 font-normal leading-5 rounded font-title text-xxs bg-mydra-light-orange text-mydra-black">
                            FixPay
                          </span>
                        </div>
                      </div>
                      <InfoTooltip
                        className="text-xs shrink-0"
                        popupProps={{
                          className: 'md:min-w-[296px]',
                        }}
                        placement="auto"
                        icon={null}
                        label={
                          <span className="pt-6 font-medium underline">
                            {tFilter('what_is_fix_pay')}
                          </span>
                        }
                      >
                        {tFilter.rich('fix_pay_tooltip_explanation', {
                          Link: (children) => (
                            <a
                              href={ROUTES.paymentOptions}
                              target="_blank"
                              className="font-semibold underline"
                            >
                              {children}
                            </a>
                          ),
                        })}
                      </InfoTooltip>
                    </label>
                  </div>
                </>
              )}
            />
          </div>
        </div>
        <div className="mx-8 h-[1] border-b border-lines"></div>

        <div className="grid grid-rows-1 gap-5 px-8 sm:grid-cols-2">
          <div className="col-span-2">
            <Headline5>{tFilter('price-range')}</Headline5>
            <div className="text-xs text-gray-light">
              {tFilter('price-example')}
            </div>
            <Controller
              control={control}
              name="budget"
              render={({ field: { onChange, value, ...field } }) => (
                <MultiRangeSlider
                  big
                  {...field}
                  onChange={onChange}
                  value={value}
                  min={0}
                  max={MAX_COURSE_FILTER_PRICE} // TODO: get from API
                  className="my-9 md:mx-24"
                  valFormatter={formatCurrencyValue}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-row justify-between items-center px-8 py-4 font-medium border-t border-lines">
          {!hideOnClear && (
            <Button
              color="white"
              onClick={(e) => {
                e.preventDefault()
                onClear?.()
              }}
              isLoading={isLoading}
              disabled={isSubmitting}
            >
              {t('clear_filters')}
            </Button>
          )}
          <Button
            type="submit"
            color="black"
            className="ml-auto"
            isLoading={isLoading || isSubmitting}
          >
            {t('show_results')}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
