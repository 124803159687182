import Image from 'next/image'
import { Button } from '@/app/modules/shared/components'
import { useTranslations } from 'next-intl'
import { EXTERNAL_LINKS } from '@/app/lib/constants'

type CoursesFooterProps = {
  wrapperProps?: React.HTMLProps<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

export const CoursesFooter = ({
  wrapperProps,
  ...props
}: CoursesFooterProps) => {
  const t = useTranslations('Courses')

  return (
    <div
      {...wrapperProps}
      className={`w-full bg-mydra-gray py-14 sm:py-20 ${wrapperProps?.className}`}
    >
      <div
        {...props}
        className={`container grid grid-rows-1 sm:grid-cols-2 gap-7 ${props.className ?? ''}`}
      >
        <div className="flex flex-col justify-center order-last gap-5 sm:order-none">
          <div className="text-3xl md:text-4xl font-title">
            {t('cant_find_course')}
          </div>
          <div className="text-base md:text-lg">{t('tell_us_interest')}</div>
          <Button
            color="black"
            onClick={() =>
              window.open(
                EXTERNAL_LINKS.courseSuggestionForm,
                '_blank',
                'noopener'
              )
            }
          >
            {t('start_now')}
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <div className="w-full md:w-3/4">
            <Image
              src={'/images/homepage-footer.png'}
              alt={t('typeform_home_banner')}
              layout="responsive"
              width={782}
              height={625}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
