import { ApiService } from './ApiService'
import {
  IApiIndividualResponse,
  IPaginationParams,
  PaginatedQueryResponse,
} from '@/types/api'
import {
  IEducationProvider,
  IEducationProviderFullUpdatePayload,
  IEducationProviderStripeLinks,
} from '@/types/education-provider'
import qs from 'qs'

export interface IEducationProviderGetFilters {}

export class EducationProviderService extends ApiService {
  static GET_PATH = `/education-providers`
  static ENDPOINT_OPTIONS = {
    hasCourses: true, // only show providers with courses
  }

  static async getEducationProviders(
    filters: IEducationProviderGetFilters = {},
    pagination: IPaginationParams = {}
  ): Promise<PaginatedQueryResponse<IEducationProvider>> {
    const queryString = qs.stringify({
      ...filters,
      ...pagination,
      ...EducationProviderService.ENDPOINT_OPTIONS,
    })

    return ApiService.get(`${EducationProviderService.GET_PATH}?${queryString}`)
  }

  static async getEducationProvider(
    id: string
  ): Promise<IApiIndividualResponse<IEducationProvider>> {
    return ApiService.get(`${EducationProviderService.GET_PATH}/${id}`)
  }

  static async putEducationProvider(
    id: string,
    data: IEducationProviderFullUpdatePayload
  ): Promise<IApiIndividualResponse<IEducationProvider>> {
    return ApiService.put(`${EducationProviderService.GET_PATH}/${id}`, data)
  }

  static async getStripeLinks(
    id: string
  ): Promise<IApiIndividualResponse<IEducationProviderStripeLinks>> {
    return ApiService.get(
      `${EducationProviderService.GET_PATH}/${id}/stripe-account-links`
    )
  }
}
