import { ICourse } from '@/types/course'
import { CourseCard } from './CourseCard'
import { Button } from '@/app/modules/shared/components'
import { useTranslations } from 'next-intl'

type CoursesListProps = {
  title: React.ReactNode
  courses: ICourse[]
  loadMore?: () => void
  isLoading?: boolean
} & React.HTMLProps<HTMLDivElement>

export const CoursesList = ({
  title,
  courses,
  loadMore,
  isLoading,
  ...props
}: CoursesListProps) => {
  const t = useTranslations('Courses')

  return (
    courses?.length > 0 && (
      <div className={`flex flex-col gap-9 ${props.className ?? ''}`}>
        <div className="text-2xl md:text-3xl font-title">{title}</div>
        <div className="grid grid-rows-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-8 gap-y-6">
          {courses.map((course) => (
            <CourseCard key={course._id} course={course} />
          ))}
        </div>
        {loadMore && (
          <div className="flex justify-center">
            <Button color="black" onClick={loadMore} isLoading={isLoading}>
              {t('load_more')}
            </Button>
          </div>
        )}
      </div>
    )
  )
}
