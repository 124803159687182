import { ICohort } from '@/types/course'
import { format, isSameYear, isValid } from 'date-fns'
import { useTranslations } from 'next-intl'
import { useFormatters } from './useFormatters'
import { useCallback } from 'react'

export function useCohortHelpers() {
  const { formatCurrencyValue } = useFormatters()
  const t = useTranslations('CohortHelpers')
  const tFormatters = useTranslations('Formatters')

  const isImmediateCohort = useCallback(
    ({
      startDate: rawStartDate,
      endDate: rawEndDate,
    }: Pick<ICohort, 'startDate' | 'endDate'>) => {
      const startDate = new Date(rawStartDate ?? '')
      const endDate = new Date(rawEndDate ?? '')

      if (!isValid(startDate) && !isValid(endDate)) {
        return true
      }

      return false
    },
    []
  )

  const isCohortActive = useCallback(
    ({
      startDate: rawStartDate,
      endDate: rawEndDate,
    }: Pick<ICohort, 'startDate' | 'endDate'>) => {
      const startDate = new Date(rawStartDate ?? '')
      const endDate = new Date(rawEndDate ?? '')

      const hasValidDates =
        rawStartDate && rawEndDate && isValid(startDate) && isValid(endDate)
      const startDateIsInTheFuture = +startDate > +new Date()
      const endDateIsInTheFuture = +endDate > +new Date()
      const startDateIsBeforeEndDate = +startDate < +endDate
      const cohortIsImmediate = isImmediateCohort({
        startDate: rawStartDate,
        endDate: rawEndDate,
      })

      return (
        cohortIsImmediate ||
        (hasValidDates &&
          startDateIsInTheFuture &&
          endDateIsInTheFuture &&
          startDateIsBeforeEndDate)
      )
    },
    [isImmediateCohort]
  )

  const formatCohortDate = useCallback(
    (
      {
        startDate: rawStartDate,
        endDate: rawEndDate,
        location,
        cost,
      }: Pick<ICohort, 'startDate' | 'endDate' | 'location' | 'cost'>,
      { includeLocation = false, includeCost = false } = {}
    ) => {
      const startDate = new Date(rawStartDate ?? '')
      const endDate = new Date(rawEndDate ?? '')

      if (isImmediateCohort({ startDate: rawStartDate, endDate: rawEndDate })) {
        return includeLocation || includeCost
          ? [
              ...(includeLocation ? [location] : []),
              ...(includeCost && cost
                ? [
                    cost.amount
                      ? formatCurrencyValue(
                          cost.amount.toString(),
                          cost.currency
                        )
                      : tFormatters('free'),
                  ]
                : includeCost && !cost
                  ? [tFormatters('price-request')]
                  : []),
            ]
              .filter(Boolean)
              .join(' — ')
          : t('start-now')
      }

      if (!isValid(startDate) || !isValid(endDate)) {
        return t('to-be-defined-acronym')
      }

      const formattedCohortDate = isSameYear(startDate, endDate)
        ? [format(startDate, 'MMM d'), format(endDate, 'MMM d, y')].join(' — ')
        : [format(startDate, 'MMM d, y'), format(endDate, 'MMM d, y')].join(
            ' — '
          )

      return [
        formattedCohortDate,
        ...(includeLocation ? [location] : []),
        ...(includeCost && cost
          ? [
              cost.amount
                ? formatCurrencyValue(cost.amount?.toString(), cost.currency)
                : tFormatters('free'),
            ]
          : includeCost && !cost
            ? [tFormatters('price-request')]
            : []),
      ]
        .filter(Boolean)
        .join(' — ')
    },
    [isImmediateCohort, formatCurrencyValue, tFormatters, t]
  )

  const isCohortFree = useCallback(
    (cohortInfo?: Pick<ICohort, 'cost'> | null) => {
      const { cost } = cohortInfo ?? {}

      if (!cost) {
        return false
      }

      return cost?.amount === 0
    },
    []
  )

  return {
    isImmediateCohort,
    isCohortActive,
    formatCohortDate,
    isCohortFree,
  }
}
