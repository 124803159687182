import { CategoryService, ICategoryGetFilters } from '@/app/services/categories'
import {
  ErrorResponse,
  IPaginationParams,
  PaginatedQueryResponse,
} from '@/types/api'
import { ICategory } from '@/types/category'
import { useQuery, UseQueryOptions } from 'react-query'

export function useCategories(
  filters: ICategoryGetFilters = {},
  pagination: IPaginationParams = {},
  options?: Omit<
    UseQueryOptions<PaginatedQueryResponse<ICategory>, ErrorResponse>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery<PaginatedQueryResponse<ICategory>, ErrorResponse>(
    [CategoryService.GET_PATH, filters, pagination],
    () => CategoryService.getCategories(filters, pagination),
    options
  )
}
