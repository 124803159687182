import { ICategory } from '@/types/category'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { ROUTES } from '@/app/lib/constants'
import { ImageWithFallback } from '../../shared/components/image-with-fallback'

type CoursesFilterRowProps = {
  categories: ICategory[]
  wrapperProps?: React.HTMLProps<HTMLDivElement>
} & React.HTMLProps<HTMLDivElement>

export const CoursesFilterRow = ({
  categories = [],
  wrapperProps,
  ...props
}: CoursesFilterRowProps) => {
  const router = useRouter()

  return (
    <div
      {...wrapperProps}
      className={`border border-x-0 border-lines ${wrapperProps?.className ?? ''}`}
    >
      <div
        {...props}
        className={`flex flex-row items-center justify-between gap-5 py-5 sm:py-7 ${props.className ?? ''}`}
      >
        <div className="hidden md:flex flex-[1] flex-row gap-5 overflow-x-scroll no-scrollbar">
          {categories.map(
            ({ _id, slug, name, icon: { data, contentType } = {} }) => {
              const image = `data:${contentType};base64,${data}`

              const isArray = Array.isArray(router.query.categories)

              const selectedCategories = router.query.categories
                ? (isArray
                    ? (router.query.categories as string[])
                    : ([router.query.categories] as string[])) || []
                : []

              const isSelected = selectedCategories.includes(_id)

              return (
                <Link
                  key={slug}
                  className={`flex flex-row items-center h-12 rounded-md border cursor-pointer hover:bg-mydra-violet hover:border-mydra-medium-purple ${isSelected ? 'bg-mydra-violet border-mydra-medium-purple' : 'bg-white border-lines'}`}
                  href={ROUTES.search + `?categories=${_id}`}
                >
                  <div className="flex items-center justify-center h-full px-2 bg-mydra-violet">
                    <div className="relative w-8 h-8">
                      <ImageWithFallback
                        src={image}
                        layout="fill"
                        objectFit="contain"
                        alt={`icon-${slug}`}
                      />
                    </div>
                  </div>
                  <div className="px-4 text-sm whitespace-nowrap">{name}</div>
                </Link>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}
