import {
  EducationProviderService,
  IEducationProviderGetFilters,
} from '@/app/services/education-providers'
import {
  ErrorResponse,
  IPaginationParams,
  PaginatedQueryResponse,
} from '@/types/api'
import { IEducationProvider } from '@/types/education-provider'
import { useQuery, UseQueryOptions } from 'react-query'

export function useEducationProviders(
  filters: IEducationProviderGetFilters = {},
  pagination: IPaginationParams = {},
  options?: Omit<
    UseQueryOptions<PaginatedQueryResponse<IEducationProvider>, ErrorResponse>,
    'queryKey' | 'queryFn'
  >
) {
  return useQuery<PaginatedQueryResponse<IEducationProvider>, ErrorResponse>(
    [EducationProviderService.GET_PATH],
    () => EducationProviderService.getEducationProviders(filters, pagination),
    options
  )
}
