import { ICourseFilters } from '@/app/services/courses'
import { CourseService } from '@/app/services/courses'
import {
  ErrorResponse,
  IPaginationParams,
  ISortingParams,
  PaginatedQueryResponse,
} from '@/types/api'
import { ICourse } from '@/types/course'
import { useRouter } from 'next/router'
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueryClient,
} from 'react-query'

export function useCourses(
  filters: ICourseFilters = {},
  pagination: IPaginationParams = {},
  sorting: ISortingParams = {},
  options?: Omit<
    UseInfiniteQueryOptions<PaginatedQueryResponse<ICourse>, ErrorResponse>,
    'queryKey' | 'queryFn'
  >
) {
  const queryClient = useQueryClient()
  const router = useRouter()

  const reset = () => queryClient.removeQueries(CourseService.GET_PATH)

  const result = useInfiniteQuery<
    PaginatedQueryResponse<ICourse>,
    ErrorResponse
  >(
    [CourseService.GET_PATH, filters, pagination],
    ({ pageParam = 1 }) =>
      CourseService.getCourses(
        filters,
        {
          limit: pagination.limit,
          page: pageParam,
        },
        sorting,
        {
          headers: {
            'accept-language': router.locale ?? router.defaultLocale ?? 'en',
          },
        }
      ),
    {
      ...options,
      getPreviousPageParam(lastPage) {
        return lastPage.metadata.page > 1
          ? lastPage.metadata.page - 1
          : undefined
      },
      getNextPageParam({ metadata: { page, limit, total } }) {
        return page * limit < total ? page + 1 : undefined
      },
    }
  )

  return {
    reset,
    ...result,
  }
}
